<template>
  <b-row>
    <b-col id="form-custom-code" cols="12" lg="6">
      <b-form-group
        id="fieldset-title"
        label="A name for this code"
        label-sr-only
      >
        <b-form-input
          v-model="code.title"
          maxlength="75"
          placeholder="Enter a name for this code"
          required
          trim
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="fieldset-summary"
        label="What's this code for?"
        label-sr-only
      >
        <b-form-input
          v-model="code.summary"
          maxlength="255"
          placeholder="Enter a description for this code (optional)"
          trim
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="fieldset-slug"
        label="Target Page(s)"
        label-sr-only
        :description="targetPages === 'selected' ? 'The page(s) where this code will be added. Hold down the Ctrl key to select multiple pages.' : ''"
      >
        <b-form-radio-group
          v-model="targetPages"
          class="ml-2"
          :options="targetPageOptions"></b-form-radio-group>
        <b-form-select
          v-if="targetPages === 'selected'"
          v-model="code.slug"
          :options="pageOptions"
          multiple
        >
        </b-form-select>
      </b-form-group>
      <b-form-group
        id="fieldset-location"
        label="Target Location"
        label-sr-only
        description="Section of the page where this code will be added."
      >
        <b-form-select
          v-model="code.options.location"
          :options="[
            { value: null, text: 'Select the Target Location', disabled: true },
            { value: 'head', text: 'Before </head>' },
            { value: 'body', text: 'Before </body>' }
          ]"
          required
        >
        </b-form-select>
      </b-form-group>
      <b-form-group
        id="fieldset-content"
        label="The Code"
        label-sr-only
        description="Can be a script or meta tag."
      >
        <b-form-textarea
          v-model="code.content"
          placeholder="Enter the code here."
          required
          trim
        ></b-form-textarea>
      </b-form-group>
      <div class="mb-3">
        <b-btn variant="danger" type="button" @click="reset">Reset</b-btn>
        <b-button
          type="button"
          variant="success"
          class="float-right"
          @click="addCode"
        >Add Code</b-button>
      </div>
    </b-col>
    <b-col>
      <p v-if="!codes.length">No codes have been added yet.</p>
      <template v-for="code in codes">
        <b-card
          v-if="!code.parent"
          :title="code.title"
          :sub-title="code.summary"
          tag="article"
          class="mb-2 position-relative"
          :key="code.id"
        >
          <b-button
            variant="danger"
            size="sm"
            class="position-absolute"
            style="top: 1px; right: 1px;"
            title="Delete this code"
            @click="deleteCode(code.id)">X</b-button>
          <b-card-text>
            {{ code.content }}
          </b-card-text>
          <b-card-text>
            <small>Before {{ `</${code.options.location}>` }} on {{ code.options.pages.join(', ') }} pages</small>
          </b-card-text>
        </b-card>
      </template>
    </b-col>
  </b-row>
</template>

<script>
const DEFAULT_SLUG = 'all'
const CONTENT_TYPE = 'tracking_code'
export default {
  name: 'TrackingCodeManager',
  data: () => ({
    targetPages: DEFAULT_SLUG,
    targetPageOptions: [
      { value: DEFAULT_SLUG, text: 'All Pages' },
      { value: 'selected', text: 'Selected Pages' },
    ],
    customPages: [],
    code: {
      slug: [DEFAULT_SLUG],
      options: {
        pages: [DEFAULT_SLUG],
        location: null,
      },
      title: null,
      summary: null,
      content: null,
    },
    codes: []
  }),
  computed: {
    pageOptions() {
      const builtInPages = {
        'location': 'Home Page',
        'location-events': 'Register Now Page (Events)',
        'location-about': 'About Page',
        'location-happening-now': 'Happening Now Page',
        'location-locations': 'Where We Play Page',
        'location-sports': 'Sports We Play Page',
        'location-registration': 'Event Registration Page',
        'location-invoice': 'Invoice Page (post registration)',
      }
      // add custom content, excluding the about page
      const pages = this.customPages.filter(page => page.slug !== 'about').map(page => {
        return {
          value: page.slug,
          text: page.title
        }
      })
      // add built in pages
      Object.keys(builtInPages).forEach(slug => {
        pages.push({
          value: slug,
          text: builtInPages[slug]
        })
      })
      return pages
    },
  },
  async mounted() {
    // Get all tracking codes for this business, excluding child codes
    this.setCodes()
    // Get all custom pages for this business
    this.customPages = await this.$store.dispatch('getPages', {
      business: this.currentBusiness.id,
      'type': ['page', 'happening_post'],
      archived: false,
    })
  },
  watch: {
    targetPages(val) {
      this.code.slug = []
      if (val === DEFAULT_SLUG) {
        this.code.slug = [DEFAULT_SLUG]
      }
    },
  },
  methods: {
    async setCodes () {
      this.codes = await this.$store.dispatch('getContent', {
        business: this.currentBusiness.id,
        'type': CONTENT_TYPE,
        archived: false,
        'exists[parent_id]': false,
      })
    },
    validateCode () {
      if (this.code.title === null) {
        this.$bvToast.toast('Please enter a name for this code.', {
          title: 'Missing Code Name',
          variant: 'danger',
          solid: true,
        })
        return false
      }
      if (this.code.options.location === null) {
        this.$bvToast.toast('Please select a target location for this code.', {
          title: 'Missing Target Location',
          variant: 'danger',
          solid: true,
        })
        return false
      }
      if (this.code.content === null) {
        this.$bvToast.toast('Please enter the code.', {
          title: 'Missing Code',
          variant: 'danger',
          solid: true,
        })
        return false
      }
      return true
    },
    /**
     * Add a new tracking code.
     * if multiple pages are selected, create an entry for each page with a parent id.
     */
     async addCode () {
      if (!this.validateCode()) {
        return
      }
      const code = { ...this.code }
      // set the code properties
      code.business = this.currentBusiness['@id']
      code.author = this.currentUser['@id']
      code.type = CONTENT_TYPE
      code.startDate = new Date()
      code.options.pages = code.slug
      // Submit the code
      if (this.targetPages === 'selected') {
        // Add a code for the parent page
        code.slug = 'selected'
        const parent = await this.$store.dispatch('postContent', code)
        console.log(parent)
        code.parent = parent['@id']
        // Add a code for each selected page
        this.code.slug.forEach(slug => {
          const page = this.pageOptions.find(page => page.value === slug)
          code.title = `${page.text} - ${code.title}`
          code.slug = slug
          this.$store.dispatch('postContent', code)
        })
      }
      else {
        code.slug = DEFAULT_SLUG
         this.$store.dispatch('postContent', code)
      }
      this.reset()
      this.setCodes()
    },
    deleteCode (codeId) {
      const children = this.codes.filter(code => code.parent === codeId)
      this.$store.dispatch('deleteContent', codeId).then(() => {
        this.setCodes()
      })
      children.forEach(child => {
        this.$store.dispatch('deleteContent', child.id)
      })
    },
    reset() {
      this.code = {
        slug: [DEFAULT_SLUG],
        title: null,
        summary: null,
        options: {
          pages: [DEFAULT_SLUG],
          location: null,
        },
        content: null,
      }
    }
  },
}
</script>

<style lang="scss">
#form-custom-code {
  border-right: 1px solid #ccc;
  fieldset {
    padding: 7px;
    legend {
      border-top: none;
    }
  }
}
</style>
